import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { getGlobalError } from '../../../selectors';
// import FadeInDown from '../../animations/FadeInDown';
// import RootStyle from '../../ui/RootStyle';
// import { DefaultButton } from '../../ui/buttons';
// import ErrorCard from '../../ui/errors/ErrorCard';
// import ErrorImage from '../../ui/errors/ErrorImage';
// import ErrorTitle from '../../ui/errors/ErrorTitle';
// import ErrorMessage from './ErrorMessage';

class RootErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.state = { eventId: null, hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error);
        // log it with sentry
        Sentry.withScope(scope => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({ eventId });
        });
    }

    goBack = () => {
        const { history } = this.props;

        // reset the state
        this.setState({ hasError: false, eventId: null });

        // move back to homepage
        history.go('/');
    };

    render() {
        // eslint-disable-next-line no-unused-vars
        const { hasError, eventId } = this.state;
        const { children /* globalError */ } = this.props;

        // if (hasError || globalError) {
        //     // render error view
        //     return (
        //         <>
        //             <RootStyle />
        //             <FadeInDown>
        //                 <ErrorCard>
        //                     <ErrorImage />
        //                     <div>
        //                         <ErrorTitle>Something&apos;s wrong here...</ErrorTitle>
        //                         <ErrorMessage eventId={eventId} />
        //                         <div>
        //                             <DefaultButton onClick={this.goBack} type="button">
        //                                 go back to the application
        //                             </DefaultButton>
        //                         </div>
        //                     </div>
        //                 </ErrorCard>
        //             </FadeInDown>
        //         </>
        //     );
        // }

        return children;
    }
}

RootErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
    globalError: PropTypes.shape({}),
    history: PropTypes.shape({
        go: PropTypes.func.isRequired,
    }).isRequired,
};

const mapStateToProps = state => ({ globalError: getGlobalError(state) });

export default compose(withRouter, connect(mapStateToProps))(RootErrorBoundary);
