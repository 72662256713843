import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { submit } from 'redux-form';
import { updateActivity, getActivity } from '../../../actions';
import { handleResponseError } from '../../../utilities/forms';
import FormError from '../../shared/form/FormError';
import PageContainer, { TitleContainer } from '../../ui/PageContainer';
import { TextButton, Actions } from '../../ui/buttons';
import FormActions from '../../ui/form/FormActions';
import Form from './Form';

const useActivity = id => {
    const [activity, setActivity] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (id) {
            dispatch(getActivity(id)).then(res =>
                setActivity({
                    ...res,
                    male_ratio: 1,
                    female_ratio: 2,
                })
            );
        }
    }, [dispatch, id, setActivity]);

    return activity;
};

const ActivityRoute = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const initialValues = useActivity(id);

    const onSubmit = useCallback(values => dispatch(updateActivity(values)).catch(handleResponseError), [dispatch]);

    const onSubmitSuccess = useCallback(() => history.push('/home'), [history]);

    const save = useCallback(() => dispatch(submit('activity')), [dispatch]);

    if (!initialValues) {
        return null;
    }

    return (
        <PageContainer>
            <FormActions.Layout>
                <FormError form="activity">{error => <FormActions.Error>{error}</FormActions.Error>}</FormError>
            </FormActions.Layout>
            <TitleContainer>
                活动表单
                <Actions>
                    <TextButton onClick={() => history.goBack()}>取消</TextButton>
                    <TextButton onClick={save}>提交修改</TextButton>
                </Actions>
            </TitleContainer>
            <Form initialValues={initialValues} onSubmit={onSubmit} onSubmitSuccess={onSubmitSuccess} />
        </PageContainer>
    );
};

export default ActivityRoute;
