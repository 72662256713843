import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import { dateFormat } from '../../../utilities/constants/formats';
import TemplateCard, { CardContainer } from '../../ui/TemplateCard';
import { DefaultButton } from '../../ui/buttons';

const TemplateItem = ({ item, onItemClick, onItemDelete }) =>
    item && (
        <TemplateCard>
            <div className="title">{item.name}</div>
            <div className="updated-time">更新时间: {format(new Date(parseISO(item.create_time)), dateFormat)}</div>
            <img alt="" className="display-image" src={item.head_img} />
            <div className="summary">{item.description}</div>
            <div className="bottom-button">
                <DefaultButton onClick={() => onItemClick(item)} type="button">
                    编辑
                </DefaultButton>
                <DefaultButton onClick={() => onItemDelete(item)} type="button">
                    删除
                </DefaultButton>
            </div>
        </TemplateCard>
    );

TemplateItem.propTypes = {
    item: PropTypes.shape({
        create_time: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        head_img: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }),
    onItemClick: PropTypes.func.isRequired,
    onItemDelete: PropTypes.func.isRequired,
};

const TemplateList = ({ items, onItemClick, onItemDelete }) => (
    <CardContainer>
        {items.map(item => (
            <TemplateItem key={item.id} item={item} onItemClick={onItemClick} onItemDelete={onItemDelete} />
        ))}
    </CardContainer>
);

TemplateList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
    onItemClick: PropTypes.func.isRequired,
    onItemDelete: PropTypes.func.isRequired,
};

export default TemplateList;
