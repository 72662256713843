import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { getUsers } from '../../../actions';
import Pagination from '../../shared/Pagination';
import PageContainer, { TitleContainer } from '../../ui/PageContainer';
import SearchInput from '../../ui/SearchInput';
import usePaging from '../../utilities/usePaging';
import UserList from './UserList';

const UsersRoute = () => {
    const dispatch = useDispatch();
    const [result, setResult] = useState(null);
    const paging = usePaging(1, 5);

    const inputRef = useRef(null);
    const [value, setValue] = useState('');
    const { onInputChange, onSubmit } = useMemo(
        () => ({
            onInputChange: () => setValue(inputRef.current.value),
            onSubmit: event => {
                event.preventDefault();
                dispatch(getUsers(paging, value)).then(setResult);

                return false;
            },
        }),
        [dispatch, paging, value]
    );

    useEffect(() => {
        dispatch(getUsers(paging)).then(setResult);
    }, [dispatch, paging, setResult]);

    const history = useHistory();
    const onItemClick = useCallback(
        item => {
            history.push(`/user/${item.id}`);
        },
        [history]
    );

    return (
        <PageContainer>
            <TitleContainer>
                用户列表 ({result?.total})
                <SearchInput inputRef={inputRef} onInputChange={onInputChange} onSubmit={onSubmit} value={value} />
            </TitleContainer>
            <UserList items={result?.list} onItemClick={onItemClick} />
            <Pagination paging={paging} records={result?.total || 0} />
        </PageContainer>
    );
};

export default UsersRoute;
